<template>
  <v-button link>
    <b
        v-clipboard="copyToClipboard"
        v-html="content.text"
    />
  </v-button>
</template>

<script>
import { VButton } from '@components/base'
import { Clipboard } from '@directives'
import { mapActions } from 'vuex'

export default {
  name: 'ProcessingSubmit',

  directives: {
    Clipboard
  },

  components: {
    VButton
  },

  props: {
    content: Object
  },

  methods: {
    ...mapActions({
      showSnackbar: 'snackbar/showSnackbar'
    }),

    copyToClipboard () {
      this.showSnackbar({
        content: this.$t('processing.copyableLink.message.linkCopied')
      })

      return window.location.href
    }
  }
}
</script>
