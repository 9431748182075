import { sendAnswersAndGetDynamicContent } from '@/api'
import { convertToFormData, debounce, hasData } from '@utils/helpers'

export default {
  data () {
    return {
      PDC_data_codes: [],
      PDC_data_cancelFetching: false,
      PDC_data_loading: false,
      PDC_data_abortController: null
    }
  },

  watch: {
    clonedSendData (answers, oldAnswers) {
      if (this.PDC_method_isEqual(answers, oldAnswers) || this.PDC_data_cancelFetching) return

      this.PDC_data_loading = true
      this.PDC_method_fetch(answers)
    }
  },

  computed: {
    PDC_computed_isDynamic () {
      return !!this.PDC_data_codes.length
    }
  },

  methods: {
    PDC_method_isEqual (answers, oldAnswers) {
      const oldValuesKeys = Object.keys(oldAnswers)
      const sameAnswersKeys = Object
        .keys(answers)
        .filter(key => oldValuesKeys.includes(key) || hasData(answers[key]))

      return sameAnswersKeys.every(key => JSON.stringify(answers[key]) === JSON.stringify(oldAnswers[key]))
    },

    PDC_method_addCode (code) {
      if (!this.PDC_data_codes.includes(code)) {
        this.PDC_data_codes.push(code)
      }
    },

    PDC_method_fetch: debounce(async function (
      answers,
      dynamicCode = this.PDC_data_codes,
      session = this.service.session.sessionUUID
    ) {
      if (this.PDC_data_abortController !== null) {
        this.PDC_data_abortController.abort()
      }

      this.PDC_data_abortController = new AbortController()

      try {
        const { data } = await this.PDC_method_sendNotSubmittedAnswers(answers, dynamicCode, session)
        this.PDC_method_fetchDynamicContent(data)
      } catch (error) {
        console.error(error)
      }

      this.PDC_data_abortController = null
      this.PDC_data_loading = false
    }, 300, this),

    PDC_method_fetchDynamicContent (newBlocks) {
      this.api.isAsync = true
      this.api.responseData = this.api.responseData.reduce((result, block) => {
        if (block.type === 'dynamic') return [...result, block, ...(newBlocks[block.dynamicCode] || [])]
        if (block.type !== 'dynamic' && block.dynamicCode) return result

        return [...result, block]
      }, [])
    },

    PDC_method_sendNotSubmittedAnswers (answers, dynamicCode, session) {
      const sendData = convertToFormData({
        session,
        ...answers,
        dynamicCode
      })

      return sendAnswersAndGetDynamicContent(sendData, {
        signal: this.PDC_data_abortController.signal
      })
    },

    PDC_method_clearCodes () {
      this.PDC_data_codes = []
    }
  }
}
