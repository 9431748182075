<template>
  <v-button
    v-clipboard="copyToClipboard"
    class="mb-16"
    link
  >
    <v-icon
      fill="primary"
      name="copy"
      size="24"
      class="mr-8"
    />

    <span v-html="content.text"/>
  </v-button>
</template>

<script>
import { Clipboard } from '@directives'
import { VButton, VIcon } from '@components/base'
import { mapActions } from 'vuex'

export default {
  name: 'ProcessingCopyableLink',

  directives: {
    Clipboard
  },

  components: {
    VButton,
    VIcon
  },

  props: {
    content: Object
  },

  methods: {
    ...mapActions({
      showSnackbar: 'snackbar/showSnackbar'
    }),

    copyToClipboard () {
      this.showSnackbar({
        content: this.$t('processing.copyableLink.message.linkCopied')
      })

      return this.content.link
    }
  }
}
</script>
