import VSelect from 'vue-select'
import { Validatable, ValidatableMultiple } from '@/mixins'
import { getType } from '@/utils/helpers'
import { createPopper } from '@popperjs/core'

export default {
  components: {
    VSelect
  },

  inject: [
    'sendData',
    'service'
  ],

  mixins: [
    Validatable,
    ValidatableMultiple
  ],

  props: {
    content: Object
  },

  data () {
    return {
      PISM_data_error: false,
      PISM_data_returnedDataPattern: (answers) => answers.map(answer => answer.code)
    }
  },

  computed: {
    PISM_computed_optionsHash () {
      return this.content.variant.reduce((result, current) => ({
        ...result,
        [current.code]: current
      }), {})
    },
    PISM_computed_answers () {
      if (!this.sendData[this.content.code]?.length) return []

      return this.sendData[this.content.code].reduce((result, code) => {
        return [
          ...result,
          this.PISM_computed_optionsHash[code] || []
        ]
      }, [])
    }
  },

  watch: {
    'service.error.isShow' () {
      if (this.isValid && this.service.error.isShow) return

      this.PISM_data_error = this.service.error.isShow
    }
  },

  mounted () {
    this.PISM_methods_checkValidation()
  },

  methods: {
    PISM_methods_setData (newAnswers) {
      if (getType(newAnswers) !== 'array') {
        newAnswers = [newAnswers]
      }

      const normalizedAnswers = this.PISM_data_returnedDataPattern(newAnswers)

      this.$set(this.sendData, this.content.code, normalizedAnswers)
      this.PISM_methods_checkValidation()
    },

    PISM_methods_checkValidation () {
      if (!this.checkMultipleMinMax()) return
      this.checkValidationByCondition(!!this.sendData[this.content.code]?.length)
    },

    /**
     * https://vue-select.org/guide/positioning.html#popper-js-integration
     * @param dropdownList
     * @param component
     * @param width
     * @return {function(): void}
     */
    PISM_methods_withPopper (dropdownList, component, { width }) {
      dropdownList.style.width = width

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle(
                'v-select_placement_top',
                state.placement === 'top'
              )
            }
          }
        ]
      })

      return () => popper.destroy()
    }
  }
}
