import { convertToFormData, getType } from '@utils/helpers'
import { getContentApi, sendContentApi } from '@/api'

export const MIXIN_PREFIX = 'PCMM'
export const SESSION_PREFIX = '_sep'

export default {
  data () {
    return {
      service: {
        getContent: (params) => this[`${MIXIN_PREFIX}_method_getContent`](params),
        sendContent: (params) => this[`${MIXIN_PREFIX}_method_sendContent`](params),
        sendSubmitContent: (params) => this[`${MIXIN_PREFIX}_method_sendSubmitContent`](params),
        session: {
          contextPath: '',
          sessionUUID: ''
        }
      }
    }
  },

  watch: {
    '$route.path' () {
      if (this.service.session.contextPath !== this.$route.params.pathMatch) {
        this.service.getContent()
      }
    }
  },

  mounted () {
    this.service.getContent()
  },

  methods: {
    [`${MIXIN_PREFIX}_method_getSession`] () {
      const contextPath = this.$route.params.pathMatch
      const storageKeyName = `${SESSION_PREFIX}.${contextPath}`

      if (sessionStorage[storageKeyName]) {
        return JSON.parse(sessionStorage[storageKeyName])
      }

      if (localStorage[storageKeyName]) {
        return JSON.parse(localStorage[storageKeyName])
      }

      return null
    },

    [`${MIXIN_PREFIX}_method_setSession`] (session = {}) {
      const { contextPath, sessionUUID, storageType } = session
      const verifiedContextPath = contextPath === this.$route.params.pathMatch
        ? contextPath
        : sessionUUID
      const storageKeyName = `${SESSION_PREFIX}.${verifiedContextPath}`

      window[`${storageType}Storage`][storageKeyName] = JSON.stringify(sessionUUID)

      this.service.session.contextPath = verifiedContextPath
      this.service.session.sessionUUID = sessionUUID

      if (contextPath !== this.$route.params.pathMatch) {
        this.$router.replace({
          path: `/${verifiedContextPath}`,
          query: this.$route.query
        })
      }
    },

    /**
     * Получение контента вопросов
     * @param {boolean=false} savePreviousAnswers - необходимость оставлять выбранные значение (например при сене языка)
     * @return {Promise<AxiosResponse<any>>}
     */
    async [`${MIXIN_PREFIX}_method_getContent`] (savePreviousAnswers = false) {
      this.startLoading()

      const sessionUUID = this[`${MIXIN_PREFIX}_method_getSession`]()
      const contextPath = this.$route.params.pathMatch
      const properties = convertToFormData({
        contextPath,
        ...(sessionUUID && { sessionUUID })
      })

      try {
        const { data } = await getContentApi(properties)
        const { contextPath, sessionUUID } = this.service.session

        if (
          data.session.contextPath !== contextPath ||
          data.session.sessionUUID !== sessionUUID
        ) {
          this[`${MIXIN_PREFIX}_method_setSession`](data.session)
        }

        this.api.isAsync = savePreviousAnswers
        this.api.responseData = data.content
        this.service.showRestrictionModal = true

        if (this.isModeExistByName('frame')) {
          this.frameable_SendPostMessageToParent({
            type: 'scroll'
          })
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        this.stopLoading()
      }
    },

    /**
     * Отправка ответа
     * @param {Object} params - объект дополнительных данных
     * @example sendContent({ time_out: true})
     */
    async [`${MIXIN_PREFIX}_method_sendContent`] (params) {
      const isValid = Object.values(this.sendData)
        .every(value => getType(value) === 'array' && !value.includes(undefined))

      if (!isValid) {
        this.showSnackbar({
          content: this.$t('site.error.reloadPage')
        })
        return false
      }

      const sendData = convertToFormData({
        session: this.service.session.sessionUUID,
        ...this.sendData,
        ...params
      })

      this.startLoading()

      try {
        await sendContentApi(sendData)
        this.service.getContent()
      } catch (error) {
        console.log(error)
      } finally {
        this.stopLoading()
      }
    },

    [`${MIXIN_PREFIX}_method_sendSubmitContent`] (params) {
      if (this.service.submitButton.uuid) {
        params = {
          submit_uuid: this.service.submitButton.uuid,
          ...params
        }
      }

      this.service.sendContent(params)
    }
  }
}
