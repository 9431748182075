import { Validatable, ValidatableMultiple } from '@/mixins'
import { ProcessingExcludeIncludeAllMixin } from '@views/Processing/mixins'

export default {
  inject: [
    'sendData',
    'service'
  ],

  mixins: [
    Validatable,
    ValidatableMultiple,
    ProcessingExcludeIncludeAllMixin
  ],

  props: {
    content: Object
  },

  computed: {
    PIC_computed_hasAnswer () {
      return this.PIC_computed_answers.length > 0
    },
    PIC_computed_answers () {
      return this.sendData[this.content.code] || []
    }
  },

  watch: {
    'service.error.isShow' () {
      if (this.isValid && this.service.error.isShow) return

      this.content.variant.forEach(item => {
        this.$set(item, 'error', this.service.error.isShow)
      })
    },

    'content.variant' (newList, prevList) {
      if (newList.length !== prevList.length) {
        this.PIC_method_checkAndSetData(this.content.answer || [])
      }
    }
  },

  mounted () {
    this.PIC_methods_checkValidation()
  },

  methods: {
    PIC_method_isDisabled (id) {
      return this.multiple_isGreaterOrEqual && !this.PIC_computed_answers.includes(id)
    },

    /**
     * Работа с параметрами исключить всё, включить всё
     * @param {string[]} newValues - новые значения
     * @param {string[]} oldValues - предыдущие значения
     * @param {string} code - код нажатого чекбокса
     * @return {string[]}
     */
    PIC_method_changeAll (newValues, oldValues, code) {
      if (!this.PEIA_computed_includeAllCode && !this.PEIA_computed_excludeAllCode) return newValues

      const isAnswersHasIncludeAll = oldValues.includes(this.PEIA_computed_includeAllCode) // выбран, или нет
      const isAnswerIncludeAll = code === this.PEIA_computed_includeAllCode // нажато исключить всё
      const isAnswersHasExcludeAll = oldValues.includes(this.PEIA_computed_excludeAllCode)
      const isAnswerExcludeAll = code === this.PEIA_computed_excludeAllCode
      let calculatedAnswers = newValues

      if (!isAnswerExcludeAll && isAnswersHasExcludeAll) {
        calculatedAnswers = newValues.filter(variant => variant !== this.PEIA_computed_excludeAllCode)
      }

      if (isAnswerIncludeAll && !isAnswersHasIncludeAll) {
        calculatedAnswers = this.content.variant
           .filter(variant => !variant.excludingAll && !variant.ignoreIncludesAll)
           .map(variant => variant.code)
      }

      if (!isAnswerIncludeAll && isAnswersHasIncludeAll) {
        calculatedAnswers = newValues.filter(variant => variant !== this.PEIA_computed_includeAllCode)
      }

      if (isAnswerExcludeAll && !isAnswersHasExcludeAll) {
        calculatedAnswers = [this.PEIA_computed_excludeAllCode]
      }

      if (
        (isAnswerExcludeAll && isAnswersHasExcludeAll) ||
        (isAnswerIncludeAll && isAnswersHasIncludeAll)
      ) {
        calculatedAnswers = []
      }

      return calculatedAnswers
    },

    /**
     * Проверка и установка answers в sendData значения.
     * Применимо, когда утановка вариантов происходит не стандартно (не через PIC_method_changed)
     */
    PIC_method_checkAndSetData (newAnswers) {
      this.$set(this.sendData, this.content.code, newAnswers)
      this.PIC_methods_checkValidation()
    },

    /**
     * Действия при нажатии на чекбокс
     * Выставляется в card-button-checkbox или v-checkbox
     * @param {string[] | string} newValues - предыдущие значения
     * @param {string[] | string} oldValues - текущие значения
     * @param {string} code - id выбранного значения
     * @example @change="PIC_method_changed"
     */
    PIC_method_changed (newValues, oldValues, code) {
      if (this.content.inactive) return

      const newAnswers = this.PIC_method_changeAll(newValues, oldValues, code)
      this.PIC_method_checkAndSetData(newAnswers)
    },

    PIC_methods_checkValidation () {
      if (!this.checkMultipleMinMax()) return
      this.checkValidationByCondition(this.PIC_computed_hasAnswer)
    }
  }
}
