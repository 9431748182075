const CHECK_SCREEN_KEY = 'check-screen-passed'

export default {
  data () {
    return {
      PCS_data_isShow: false,
      PDC_data_content: null
    }
  },

  methods: {
    PDC_method_addContent (content) {
      this.PDC_data_content = content
      this.PCS_data_isShow = !this.PDS_method_getPassedStatus()
    },

    PDC_method_setPassed () {
      this.PCS_data_isShow = false
      this.PDS_method_setPassedStatus()
    },

    PDS_method_getPassedStatus () {
      return sessionStorage.getItem(`${CHECK_SCREEN_KEY}_${this.service.session.sessionUUID}`) === 'true'
    },

    PDS_method_setPassedStatus (isShowed = true) {
      sessionStorage.setItem(`${CHECK_SCREEN_KEY}_${this.service.session.sessionUUID}`, `${isShowed}`)
    }
  }
}
