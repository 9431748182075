<template>
  <div>
    <div
      v-if="hasImg"
      class="row mb-24"
    >
      <div class="col">
        <img
          :src="imgUrl"
          class="question-img"
          alt=""
        />
      </div>
    </div>

    <div
      v-if="content.text"
      class="row mb-24"
    >
      <div class="col">
        <span v-html="content.text"/>
      </div>
    </div>

    <div
      :class="classes"
      v-resize="onResize"
      class="v-radio-media"
    >
      <v-sheet
        ref="cardButtons"
        v-for="variant in content.variant"
        :key="variant.code"
        :color="variant.error && !content.inactive ? 'danger' : null"
        :cover="hasContentImg(variant)"
        :style="sheetStyles"
        :value="PIRM_computed_answer === variant.code"
        :readonly="content.inactive"
        outlined
        rounded
        @click.prevent="PIRM_methods_setData(variant.code)"
        class="v-radio-media__item"
      >
        <div
          v-if="hasContentImg(variant)"
          class="v-radio-media__img"
          :style="{
            backgroundImage: `url(${getContentImgUrl(variant)})`,
            ...imageStyles
          }"
        />

        <div
          v-else
          class="v-radio-row"
        >
          <div class="v-radio-row__radio">
            <v-radio
              :model-value="PIRM_computed_answer"
              :value="variant.code"
            />
          </div>

          <v-divider vertical/>

          <div class="v-radio-row__text">
            <span v-html="variant.text"/>
          </div>
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import { Resize } from '@directives'
import ProcessingImgMixin from '@views/Processing/mixins/ProcessingImgMixin'
import { VDivider, VRadio, VSheet } from '@components/base'
import ProcessingInputRadioMixin from './ProcessingInputRadioMixin'

export default {
  name: 'ProcessingInputRadioMedia',

  directives: {
    Resize
  },

  components: {
    VDivider,
    VRadio,
    VSheet
  },

  inject: [
    'sendData',
    'service'
  ],

  mixins: [
    ProcessingInputRadioMixin,
    ProcessingImgMixin
  ],

  data () {
    return {
      cardButtonHeight: 0,
      isOversize: false,
      imgLoadedCount: 1
    }
  },

  computed: {
    sheetStyles () {
      const defaultNumber = {
        xs: 2,
        sm: 2,
        md: 4,
        lg: 5,
        xl: 5
      }
      const width = 100 / (this.content[`_number_of_variants_${this.service.breakpoint.current}`] ||
        defaultNumber[this.service.breakpoint.current]) + '%'

      return {
        width: `calc(${width} - 0.5rem)`,
        minHeight: this.cardButtonHeight === 0
          ? null
          : `${this.cardButtonHeight}px`
      }
    },
    imageStyles () {
      return {
        paddingBottom: (this.content[`_height_${this.service.breakpoint.current}`] || '100%')
      }
    },
    classes () {
      return {
        'v-radio-media_is_image': this.isImgButtons,
        'v-radio-media_is_oversize': this.isOversize,
        'v-radio-media_is_inactive': this.content.inactive
      }
    }
  },

  methods: {
    /**
     * Действие при изменении размеров окна
     */
    onResize () {
      if (this.isImgButtons) return
      this.setCardButtonHeight()
    },

    /**
     * Подсчёт и установка высоты на кнопку
     * Если текст вылазит за пределы кнопки, isOversize = true
     */
    setCardButtonHeight () {
      this.cardButtonHeight = 0
      this.isOversize = false

      this.$nextTick(() => {
        let i = this.$refs.cardButtons.length

        while (i-- && !this.isOversize) {
          const el = this.$refs.cardButtons[i].$el

          if (el.clientHeight > this.cardButtonHeight) {
            this.cardButtonHeight = el.clientHeight + 2
          }

          if (!this.isImgButtons && el.scrollWidth > el.offsetWidth) {
            this.cardButtonHeight = 0
            this.isOversize = true
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "~@assets/styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.v-radio-row {
  display: flex;
  align-items: center;
  height: 100%;

  &__radio {
    padding: .5rem
  }

  &__text {
    padding: .5rem
  }
}

.v-radio-media {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.25rem;

  &__item {
    width: auto;
    padding: 0;
    margin: .25rem;
  }

  &__img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &_is {
    &_image {
      .v-radio-media__img {
        filter: grayscale(100%);
      }

      .v-radio-media__item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 .25rem;

        &.v-sheet_is_active {
          .v-radio-media__img {
            filter: grayscale(0);
          }
        }
      }
    }

    &_inactive {
      .v-radio-media__img {
        filter: grayscale(0);
      }
    }

    &_oversize {
      .v-radio-media__item {
        flex: 1 0 100%;
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
</style>
